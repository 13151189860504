import React, { useEffect } from "react";
import { Router, useLocation } from "@reach/router";
import PrivateRoute from "../../components/app/privateRoute";
import Layout from "../../components/layout";
import Settings from "../../components/account/settings";
import Home from "../../components/account/home";
import AlbumOverview from "../../components/account/albumOverview";
import CreateAlbum from "../../components/album/create";
import SEO from "../../components/seo";

const Account = () => {
  const location = useLocation();

  useEffect(() => {
    setTimeout(() => {
      document.getElementById("layoutHeader").scrollIntoView();
    }, 100);
  }, [location.pathname]);

  return (
    <Layout>
      <SEO title="Account" />
      <Router className="flex flex-col flex-grow" basepath="/account">
        <PrivateRoute path="/" component={Home} />
        <PrivateRoute path="/settings" component={Settings} />
        <PrivateRoute path="/albums" component={AlbumOverview} />
        <PrivateRoute path="/create" component={CreateAlbum} />
      </Router>
    </Layout>
  );
};

export default Account;
