import { Link, navigate } from "gatsby";
import React from "react";
import { useState } from "react";
import Confetti from "react-confetti";
import { ETHERSCAN_TXN } from "../../../../constants";
import { useAuthentication } from "../../../../hooks/auth/useLogin";
import { useCreateAlbum } from "../../../../hooks/createAlbum/useCreateAlbum";
import { useWindowSize } from "../../../../hooks/createAlbum/useWindowSize";
import { useProfile } from "../../../../hooks/profile/useProfile";
import {
  useCreateAlbumDetails,
  useSelectedNfts,
} from "../../../../state/createAlbum/hooks";
import Button from "../../../common/button/button";
import Modal from "../../../common/modal/modal";
import ModalSubtitle from "../../../common/modal/modalSubtitle";
import ModalTitle from "../../../common/modal/modalTitle";
import Loading from "../../../loading/loading";
import CreateAlbumHeader from "../createAlbumHeader";
import CreatePreview from "../createPreview";

const Confirm = () => {
  const { user } = useAuthentication();
  const [profile] = useProfile(user?.uid);

  const [showConfirm, setShowConfirm] = useState(false);

  const { details, set } = useCreateAlbumDetails();
  const selectedNfts = useSelectedNfts();

  const { createAlbumCallback } = useCreateAlbum();
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const [successTxnUrl, setSuccessTxnUrl] = useState("");
  const [success, setSuccess] = useState(false);

  const handelCloseConfirm = () => {
    setShowConfirm(false);
  };

  const handleCreateAlbum = () => {
    setLoading(true);
    setShowConfirm(false);

    createAlbumCallback()
      .then((response) => {
        const txHash = response;
        if (txHash) {
          set({
            ...details,
            createdHash: txHash,
          });

          setSuccessTxnUrl(`${ETHERSCAN_TXN}${txHash}`);
          setSuccess(true);
        } else {
          setSuccess(false);
          setErrorMsg("Error creating album");
        }
      })
      .catch((error) => {
        setErrorMsg("Error creating album");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const { width, height } = useWindowSize();
  return (
    <>
      <Confetti run={success} height={height} width={width} />
      <div className="flex flex-col space-y-32 h-full">
        <Modal isOpen={showConfirm} onClose={handelCloseConfirm}>
          <ModalTitle>
            <h2>Confirm</h2>
          </ModalTitle>
          <div className="flex flex-col space-y-24 ">
            <ModalSubtitle>
              <p>
                You will NOT be able to remove the NFTs you put into this album
                once it is created.
              </p>
              <br />
              <p>Continue creating album?</p>
            </ModalSubtitle>
            <div className="flex flex-row mt-32 justify-between space-x-16">
              <Button
                size="md"
                width="full"
                onClick={handelCloseConfirm}
                fill={false}
              >
                cancel
              </Button>
              <Button size="md" width="full" onClick={handleCreateAlbum}>
                create
              </Button>
            </div>
          </div>
        </Modal>
        <CreateAlbumHeader
          title={success ? "Congratulations!" : "Confirm"}
          showContinue={false}
          celebrate={success}
        />
        <div
          className="flex flex-col space-y-32 mx-auto items-center"
          style={{ width: "326px" }}
        >
          <div className="flex flex-col space-y-16">
            <h1 className="text-white self-start">
              {success ? "Your album is pending!" : "Final confirmation!"}
            </h1>
            <p className="body1 text-dark-light-grey">
              Once you create this album you will NOT be able to remove the NFTs
              you put into this album once it is created.
            </p>
          </div>
          <CreatePreview
            creator={
              profile?.username
                ? profile?.username
                : `${user?.uid.slice(0, 5)}...${user?.uid.slice(-4)}`
            }
            details={details}
            selectedNfts={selectedNfts}
          />
          {success ? (
            <div className="flex flex-col space-y-16">
              <Link to="/account/albums">
                <Button width="full">View album status</Button>
              </Link>
              <a href={successTxnUrl} target="_blank">
                <Button fill={false}>View Etherscan transaction</Button>
              </a>
            </div>
          ) : (
            <div className="flex flex-row w-full space-x-16">
              <div className="w-1/2">
                <Button
                  fill={false}
                  width="full"
                  onClick={() => navigate("/account/albums")}
                  disabled={loading}
                >
                  Cancel &amp; Exit
                </Button>
              </div>
              <div className="w-1/2">
                <Button
                  onClick={handleCreateAlbum}
                  disabled={loading}
                  width="full"
                >
                  {loading ? <Loading size={24} /> : "Create"}
                </Button>
              </div>
            </div>
          )}
          <p className="body2 text-dark-red">{errorMsg}</p>
        </div>
      </div>
    </>
  );
};

export default Confirm;
