import { Web3Provider } from "@ethersproject/providers";
import { ABI } from "../../../constants";
import { CONTRACT_ADDRS } from "../../../constants/contractAddrs";
import { Contract } from "ethers";
import { defaultAbiCoder, keccak256, toUtf8Bytes } from "ethers/lib/utils";

//Retrieve the owner for an ens subname
export const getENSOwner = async (
  library: Web3Provider,
  name: string
): Promise<string> => {
  //Get base ens node
  const factory = new Contract(
    CONTRACT_ADDRS.ALBUM_FACTORY,
    ABI.ALBUM_FACTORY,
    library
  );
  const baseENSNode = await factory.BASE_ENS_NODE();

  const contract = new Contract(
    CONTRACT_ADDRS.ENS_REGISTRY,
    ABI.ENS_REGISTRY,
    library
  );
  const encoded = defaultAbiCoder.encode(
    ["bytes32", "bytes32"],
    [baseENSNode, keccak256(toUtf8Bytes(name))]
  );
  const node = keccak256(encoded);
  return contract.owner(node) as string;
};
