import React from "react";
import { useProfile } from "../../hooks/profile/useProfile";
import Banner from "../profile/banner";
import ProfileDescription from "../profile/details/profileDescription";
import ProfileTitle from "../profile/details/profileTitle";
import SocialMedia from "../profile/details/socialMedia";
import firebase from "gatsby-plugin-firebase";
import { Link } from "gatsby";
import Button from "../common/button/button";
import AccountContainer from "./accountContainer";
import { useProfilePic } from "../../hooks/profile/useProfilePic";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import MobileProfileDropdown from "../common/dropdown/mobileProfileDropdown";

const Home = () => {
  const dropdownChoices = [
    { option: "Edit profile", navigateTo: "/account/settings" },
  ];

  //In case the user logs out on a different window
  if (!firebase?.auth()?.currentUser?.uid) {
    return null;
  }

  //The uid should always be populated because of the private route requiring a logged in user
  const [user, loading] = useProfile(firebase.auth().currentUser.uid);
  const profilePic = useProfilePic(user?.address);

  if (loading) {
    return null;
  }

  return (
    <div
      className="flex flex-col space-y-24 md:space-y-32"
      style={{ minHeight: "100vh" }}
    >
      <Banner userId={user.address} />
      <div className="h-full grid grid-cols-12 md:gap-24 px-8 md:px-24 mt-0 md:mt-24 w-full">
        <div className="flex flex-col space-y-8 md:space-y-24 md:pb-48 col-span-12 md:col-span-3">
          <div className="flex flex-row w-full">
            <div className="relative min-w-1/4 md:min-w-1/2 max-w-86 md:max-w-196 pb-32 md:pb-96">
              <div className="absolute w-80 md:w-196 h-80 md:h-196 md:h-bg-dark-background border-8 md:border-12 border-dark-background bg-clip-content rounded-full transform -translate-y-2/3 md:-translate-y-1/2">
                <img
                  className="h-full w-full rounded-full object-cover bg-dark-background"
                  src={profilePic}
                />
              </div>
            </div>
            <div className="flex flex-row w-full justify-end md:hidden pr-8">
              <MobileProfileDropdown
                dropdownIcon={
                  <MoreVertIcon className="fill-current text-dark-white-64" />
                }
                choices={dropdownChoices}
                user={user}
              />
            </div>
          </div>

          <div className="flex flex-col px-8 space-y-16 md:space-y-24">
            <ProfileTitle user={user} />
            <Link className="hidden md:block" to="/account/settings">
              <Button size="sm">Edit profile</Button>
            </Link>
            <ProfileDescription
              description={user.description}
              joined={user.createdAt._seconds}
            />
            <div className="hidden md:block">
              <SocialMedia user={user} />
            </div>
          </div>
        </div>

        <div className="flex flex-col flex-grow w-full h-full col-span-12 md:col-span-9 pt-24 md:pt-0">
          <AccountContainer user={user} />
        </div>
      </div>
    </div>
  );
};

export default Home;
