import { useEffect, useState } from "react";
import Api from "../../services/api";
import useAuthState from "../auth/useAuthState";
import firebase from "gatsby-plugin-firebase";

export const useWhitelist = (): [boolean, boolean] => {
  const [loading, setLoading] = useState(true);
  const [isWhitelisted, setIsWhitelisted] = useState(false);
  const [user, userLoading] = useAuthState(firebase);

  useEffect(() => {
    if (!userLoading) {
      //Call when we finish fetching the user
      if (user) {
        const api = new Api();
        api
          .isOnWhitelist(user.uid)
          .then((response) => {
            setIsWhitelisted(response.data.whitelisted);
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        setLoading(false);
      }
    }
  }, [userLoading, user]);

  return [isWhitelisted, loading];
};
