import React, { useState, FC, useEffect } from "react";
import { Link } from "gatsby";
import { User } from "../../models/user";
import GalleryTab from "../profile/tabs/galleryTab";
import AlbumsTab from "../profile/tabs/albumsTab";
import LikesTab from "../profile/tabs/likesTab";
import Button from "../common/button/button";
import Hint from "../common/hint/hint";
import { useGetCombinedNFTs } from "../../hooks/nfts/useGetCombinedNFTs";
import { useGetAlbumsCreated } from "../../hooks/albums/useGetAlbumsCreated";
import { useGetAlbumsOwned } from "../../hooks/albums/useGetAlbumsOwned";

interface AccountContainerProps {
  user: User;
}

const AccountContainer: FC<AccountContainerProps> = ({ user }) => {
  const [tabState, setTabState] = useState<string>("");
  const [combinedNFTs, combinedLoading, combinedError] = useGetCombinedNFTs(
    user.address
  );
  const [createdAlbums, createdAlbumsLoading, createdAlbumsError] =
    useGetAlbumsCreated(user.address);
  const [ownedAlbums, ownedAlbumsLoading, ownedAlbumsError] = useGetAlbumsOwned(
    user.address
  );

  useEffect(() => {
    let accountParams = new URLSearchParams(location.search.substring(1));
    let tab = accountParams.get("tab");
    if (tab) {
      setTabState(tab);
    } else {
      setTabState("");
    }
  }, [location.search]);

  return (
    <div className="flex flex-col flex-grow w-full h-full">
      {/* TABS and Curate Gallery */}
      <div className="flex flex-row pt-0 md:pt-17 px-8 md:px-0">
        <div className="flex flex-row flex-grow space-x-32 items-center">
          <Link to={`${location.pathname}`}>
            <h1
              className={`${
                tabState === "" ? "text-dark-red" : "text-dark-white-64"
              } md:hover:text-white`}
            >
              Gallery
            </h1>
          </Link>

          <Link to={`${location.pathname}?tab=albums`}>
            <h1
              className={`${
                tabState === "albums" ? "text-dark-red" : "text-dark-white-64"
              } md:hover:text-white`}
            >
              Albums
            </h1>
          </Link>
          <Link to={`${location.pathname}?tab=likes`}>
            <h1
              className={`${
                tabState === "likes" ? "text-dark-red" : "text-dark-white-64"
              } md:hover:text-white`}
            >
              Likes
            </h1>
          </Link>
        </div>
        <div className="hidden md:block">
          <Hint title="Gallery curation coming soon">
            <Button fill={false} disabled={true} size="sm">
              Curate Gallery
            </Button>
          </Hint>
        </div>
      </div>
      <div
        className="flex flex-row flex-grow bg-primary-dark-grey my-16 md:my-32 mx-8 md:mx-0"
        style={{ height: "1px" }}
      />

      <div className="flex flex-col w-full h-full px-8 md:px-0">
        {tabState === "" ? (
          <GalleryTab loading={combinedLoading} combinedNFTs={combinedNFTs} />
        ) : null}
        {tabState === "albums" ? (
          <AlbumsTab
            createdAlbums={createdAlbums}
            createdAlbumsLoading={createdAlbumsLoading}
            ownedAlbums={ownedAlbums}
            ownedAlbumsLoading={ownedAlbumsLoading}
          />
        ) : null}
        {tabState === "likes" ? <LikesTab user={user} /> : null}
      </div>
    </div>
  );
};

export default AccountContainer;
