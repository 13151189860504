import React, { FC } from "react";

interface BannerImgProps {
  url: string;
}

const BannerImg: FC<BannerImgProps> = ({ url }) => (
  <img className="w-full h-full object-cover" src={url} />
);

export default BannerImg;
