import { Snackbar } from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import { useAuthentication } from "../../hooks/auth/useLogin";
import { useProfileBanner } from "../../hooks/profile/useProfileBanner";
import { useProfilePic } from "../../hooks/profile/useProfilePic";
import { UpdateUserRequest, User } from "../../models/user";
import Api from "../../services/api";
import { validateSocialMedia } from "../../utils/profile/utils";
import { isEmailValid } from "../../utils/utils";
import Button from "../common/button/button";
import Card from "../common/card/card";
import ImageUpload from "../common/form/input/image/imageUpload";
import TextArea from "../common/form/input/textArea";
import TextInput from "../common/form/input/textInput";
import Loading from "../loading/loading";
import { Link } from "gatsby";

const Settings = () => {
  const { user } = useAuthentication();
  useEffect(() => {
    if (user) {
      getUserData();
    }
  }, [user]);

  const originalProfilePic = useProfilePic(user?.uid);
  const originalBannerPic = useProfileBanner(user?.uid);

  const getUserData = () => {
    const api = new Api();
    api
      .getProfile(user.uid)
      .then((user) => {
        setName(user.name);
        setUsername(user.username);
        setBio(user.description);
        setEmail(user.email);
        setWebsite(user.website);
        setTwitter(user.twitter);
        setDiscord(user.discord);
        setYoutube(user.youtube);
        setOpensea(user.opensea);
        setFoundaton(user.foundation);
        setPulledUserInfo(user);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Bio stuff
  const [pulledUserInfo, setPulledUserInfo] = useState<User | undefined>(
    undefined
  );
  const [userUpdated, setUserUpdated] = useState<boolean>(false);
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [bio, setBio] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [usernameError, setUsernameError] = useState("");

  useEffect(() => {
    if (userUpdated) {
      getUserData();
      setUserUpdated(false);
    }
  }, [userUpdated]);

  const handleEmailChange = (e) => {
    const email = e.target.value;
    if (isEmailValid(email) || email.length === 0) {
      setEmailError("");
    } else {
      setEmailError("Please enter a valid email");
    }

    setEmail(email);
  };

  // Images
  const [profilePic, setProfilePic] = useState<string | undefined>();
  const handleProfilePicChange = (imageUrl) => {
    if (imageUrl) {
      setProfilePic(imageUrl);
    }
  };
  const [banner, setBanner] = useState<string | undefined>();
  const handleBannerChange = (imageUrl) => {
    if (imageUrl) {
      setBanner(imageUrl);
    }
  };

  const handleNameChange = (e) => {
    let Regex = /^\s*\s*$/;
    let result = e.target.value.replace(Regex, "");
    setName(result);
  };

  const handleUsernameChange = (e) => {
    let Regex = /^\s*\s*$/;
    let result = e.target.value.replace(Regex, "");
    setUsername(result);
  };

  const checkUsername = () => {
    if (pulledUserInfo.username === username) {
      setUsernameError("");
    } else {
      const api = new Api();

      api
        .checkUsername(username)
        .then((response) => {
          if (!response) {
            setUsernameError("Username taken");
          } else {
            setUsernameError("");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  // TODO: twitter verification stuff?

  // Social media
  const [website, setWebsite] = useState("");
  const [twitter, setTwitter] = useState("");
  const [discord, setDiscord] = useState("");
  const [youtube, setYoutube] = useState("");
  const [opensea, setOpensea] = useState("");
  const [foundation, setFoundaton] = useState("");

  const [updateStatus, setUpdateStatus] = useState<
    "success" | "error" | undefined
  >();
  const handleCloseStatus = () => {
    setUpdateStatus(undefined);
  };

  const [isSavingChanges, setIsSavingChanges] = useState(false);

  const handleSaveChanges = (e) => {
    e.preventDefault();

    setIsSavingChanges(true);

    //If it didnt change just make undefined and dont update
    const data: UpdateUserRequest = {
      name: name !== pulledUserInfo?.name ? name : undefined,
      username: username !== pulledUserInfo?.username ? username : undefined,
      description: bio !== pulledUserInfo?.description ? bio : undefined,
      email: email !== pulledUserInfo?.email ? email : undefined,
      website: website !== pulledUserInfo?.website ? website : undefined,
      twitter: twitter !== pulledUserInfo?.twitter ? twitter : undefined,
      discord: discord !== pulledUserInfo?.discord ? discord : undefined,
      youtube: youtube !== pulledUserInfo?.youtube ? youtube : undefined,
      opensea: opensea !== pulledUserInfo?.opensea ? opensea : undefined,
      foundation:
        foundation !== pulledUserInfo?.foundation ? foundation : undefined,
    };

    const api = new Api();
    api
      .updateUser(data, profilePic, banner)
      .then((response) => {
        if (response.status === 200) {
          setUpdateStatus("success");
          setUserUpdated(true);
        } else {
          setUpdateStatus("error");
        }
      })
      .catch((error) => {
        console.log(error);
        setUpdateStatus("error");
      })
      .finally(() => {
        setIsSavingChanges(false);
      });
  };

  //This validates our inputs
  const checkValidInputs = (): boolean => {
    const socialMediaValid =
      validateSocialMedia("website", website) === undefined &&
      validateSocialMedia("twitter", twitter) === undefined &&
      validateSocialMedia("discord", discord) === undefined &&
      validateSocialMedia("youtube", youtube) === undefined &&
      validateSocialMedia("opensea", opensea) === undefined &&
      validateSocialMedia("foundation", foundation) === undefined;

    return socialMediaValid && !usernameError;
  };

  const noValuesChanged = (): boolean => {
    const noChange =
      pulledUserInfo?.name === name &&
      pulledUserInfo?.username === username &&
      pulledUserInfo?.description === bio &&
      pulledUserInfo?.email === email &&
      pulledUserInfo?.website === website &&
      pulledUserInfo?.twitter === twitter &&
      pulledUserInfo?.discord === discord &&
      pulledUserInfo?.youtube === youtube &&
      pulledUserInfo?.opensea === opensea &&
      pulledUserInfo?.foundation === foundation &&
      profilePic === undefined &&
      banner === undefined;

    return noChange;
  };

  return (
    <div className="flex">
      <Snackbar
        open={updateStatus && updateStatus.length > 0}
        autoHideDuration={3000}
        onClose={handleCloseStatus}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <div>
          {updateStatus === "success" && (
            <div
              className="bg-white caption p-16 bg-opacity-90"
              style={{ borderRadius: "4px" }}
            >
              <p className="caption">Save success</p>
            </div>
          )}
          {updateStatus === "error" && (
            <div
              className="bg-dark-red caption p-16 bg-opacity-90"
              style={{ borderRadius: "4px" }}
            >
              <p className="caption">There was an error updating profile</p>
            </div>
          )}
        </div>
      </Snackbar>
      <div className="flex flex-col space-y-16 lg:space-y-32 my-16 lg:my-48 mx-auto">
        <div className="flex flex-col space-y-16 md:items-center lg:flex-row lg:space-y-0 lg:items-start md:justify-between">
          <h1 className="md:hidden text-white">Edit profile</h1>
          <p className="hidden md:block display1 text-white">Edit profile</p>
          <div className="hidden lg:flex flex-row space-x-16">
            <Button
              fill={false}
              disabled={
                isSavingChanges || !checkValidInputs() || noValuesChanged()
              }
              onClick={handleSaveChanges}
            >
              {isSavingChanges ? <Loading size={24} /> : <>Save changes</>}
            </Button>
            <Link to="/account">
              <Button>View profile</Button>
            </Link>
          </div>
        </div>
        <div className="flex flex-col space-x-0 space-y-16 items-center lg:flex-row lg:space-x-24 lg:space-y-0 lg:items-start">
          <div className="flex flex-col space-y-16 lg:space-y-24">
            <Card title="Details">
              <TextInput
                label="name"
                placeholder="First and last"
                value={name}
                onChange={handleNameChange}
              />
              <TextInput
                label="username"
                placeholder="Username"
                value={username}
                onChange={handleUsernameChange}
                onBlur={checkUsername}
                error={usernameError}
              />
            </Card>
            <Card title="Bio">
              <TextArea
                minRows={5}
                value={bio}
                onChange={(e) => setBio(e.target.value)}
              />
            </Card>
            <Card title="Email notifications">
              <p className="body1">
                Receive important notifications about albums you create and own.
                Your email will not be shown anywhere on your profile.
              </p>
              <TextInput
                label="email"
                placeholder="Your email"
                value={email}
                error={emailError}
                onChange={handleEmailChange}
              />
            </Card>
          </div>
          <div className="flex flex-col space-y-16 lg:space-y-24">
            <ImageUpload
              url={originalProfilePic}
              previewSize="profile"
              title="Profile pic"
              onChange={handleProfilePicChange}
              subtitle={
                "Recommended size 500x500px\nJPG, PNG, or GIF\n10MB max size"
              }
            />
            <ImageUpload
              url={originalBannerPic}
              previewSize="banner"
              title="Profile banner"
              onChange={handleBannerChange}
              subtitle={
                "Recommended size 1500x500px\nJPG, PNG, or GIF\n10MB max size"
              }
            />
            {/* TODO: add twitter verification later */}
            {/* <Card title="Verify your profile">
                            <p className="body1">Let people know your SZNS profile is really you</p>
                            <Button fill={false} width="full">Verify with Twitter</Button>
                        </Card> */}
          </div>
          <div className="flex flex-col space-y-16 lg:space-y-24">
            <Card title="Add links">
              <TextInput
                label="website"
                placeholder="Website URL"
                value={website}
                onChange={(e) => setWebsite(e.target.value)}
                error={validateSocialMedia("website", website)}
              />
              <TextInput
                label="twitter"
                placeholder="twitter.com/Username"
                value={twitter}
                onChange={(e) => setTwitter(e.target.value)}
                error={validateSocialMedia("twitter", twitter)}
              />
              <TextInput
                label="discord username"
                placeholder="[username]#0000"
                value={discord}
                onChange={(e) => setDiscord(e.target.value)}
                error={validateSocialMedia("discord", discord)}
              />
              <TextInput
                label="youtube"
                placeholder="youtube.com/channel/chan_name"
                value={youtube}
                onChange={(e) => setYoutube(e.target.value)}
                error={validateSocialMedia("youtube", youtube)}
              />
              <TextInput
                label="opensea"
                placeholder="opensea.io/Username"
                value={opensea}
                onChange={(e) => setOpensea(e.target.value)}
                error={validateSocialMedia("opensea", opensea)}
              />
              <TextInput
                label="foundation"
                placeholder="foundation.app/Username"
                value={foundation}
                onChange={(e) => setFoundaton(e.target.value)}
                error={validateSocialMedia("foundation", foundation)}
              />
            </Card>
          </div>
        </div>
        <div
          className="flex flex-row space-x-16 lg:hidden sticky bottom-0 bg-dark-background z-30 items-center"
          style={{ height: "76px" }}
        >
          <Button
            fill={false}
            disabled={
              isSavingChanges || !checkValidInputs() || noValuesChanged()
            }
            onClick={handleSaveChanges}
          >
            {isSavingChanges ? <Loading size={24} /> : <>Save changes</>}
          </Button>
          <Link to="/account">
            <Button>View profile</Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Settings;
