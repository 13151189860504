import { useEthers } from "@usedapp/core";
import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PublicResolver as resolverAbi } from "@ensdomains/ens-contracts";
import { ABI } from "../../constants";
import Api from "../../services/api";
import { AppState } from "../../state";
import useAuthState from "../../hooks/auth/useAuthState";
import firebase from "gatsby-plugin-firebase";
import { parseUnits, solidityKeccak256 } from "ethers/lib/utils";
import { nameprep } from "@ethersproject/strings";
import { Contract, ethers } from "ethers";
import { CONTRACT_ADDRS } from "../../constants/contractAddrs";
import {
  addTransaction,
  TransactionType,
} from "../../state/transactions/actions";
import { useAddPopup } from "../../state/application/hooks";
import { CHAIN_ID } from "../../constants";

export const useCreateAlbum = () => {
  const { library } = useEthers();
  const dispatch = useDispatch();
  const addPopup = useAddPopup();
  const [user] = useAuthState(firebase);

  const factoryAddress = useMemo(() => {
    return CONTRACT_ADDRS.ALBUM_FACTORY;
  }, []);

  const abi = useMemo(() => {
    return ABI.ALBUM_FACTORY;
  }, []);

  const signer = useMemo(() => {
    return library.getSigner();
  }, []);

  const name = useSelector<
    AppState,
    AppState["createAlbum"]["details"]["name"]
  >((state) => state.createAlbum.details.name);
  const symbol = useSelector<
    AppState,
    AppState["createAlbum"]["details"]["tokenName"]
  >((state) => state.createAlbum.details.tokenName);
  const tokensPerWei = useSelector<
    AppState,
    AppState["createAlbum"]["details"]["tokensPerWei"]
  >((state) => state.createAlbum.details.tokensPerWei);
  const saleStart = useSelector<
    AppState,
    AppState["createAlbum"]["details"]["saleEnd"]
  >((state) => state.createAlbum.details.saleStart);
  const saleEnd = useSelector<
    AppState,
    AppState["createAlbum"]["details"]["saleEnd"]
  >((state) => state.createAlbum.details.saleEnd);
  const description = useSelector<
    AppState,
    AppState["createAlbum"]["details"]["description"]
  >((state) => state.createAlbum.details.description);
  const amountSold = useSelector<
    AppState,
    AppState["createAlbum"]["details"]["amountSold"]
  >((state) => state.createAlbum.details.amountSold);
  const totalSupply = useSelector<
    AppState,
    AppState["createAlbum"]["details"]["totalSupply"]
  >((state) => state.createAlbum.details.totalSupply);
  const nfts = useSelector<AppState, AppState["createAlbum"]["selectedNfts"]>(
    (state) => state.createAlbum.selectedNfts
  );

  const banner = useSelector<
    AppState,
    AppState["createAlbum"]["details"]["pic"]
  >((state) => state.createAlbum.details.pic);

  //Contract call to create album
  const createAlbumContract = useCallback(
    async (snapshotConfigHash: string): Promise<any> => {
      const nftsArg = nfts.reduce(
        (prev, { id, type: { contract: address } }) => ({
          ...prev,
          [address]: [...(prev[address] ?? []), id],
        }),
        {}
      );

      const albumId = name.toLowerCase().trim().replace(/\s/g, "");
      const factoryContract = new Contract(factoryAddress, abi, signer);
      if (albumId != nameprep(albumId)) {
        throw new Error(
          `Name ${albumId} not formatted properly; should be nameprepped to ${nameprep(
            albumId
          )}`
        );
      }
      const baseEnsNode = await factoryContract.BASE_ENS_NODE();
      const subnode = solidityKeccak256(
        ["bytes32", "bytes32"],
        [baseEnsNode, solidityKeccak256(["string"], [albumId])]
      );
      const resolverInterface = new ethers.utils.Interface(resolverAbi);
      const resolverCalldata = [
        { k: "snapshot", v: "ipfs://" + snapshotConfigHash },
        { k: "url", v: `https://szns.io/album/${albumId}` },
        // [DAO] SZNS Albums Governance Requirements V2 (2).pdf
        // https://storageapi2.fleek.co/sk4re-team-bucket/dao_requirements/[DAO]%20SZNS%20Albums%20Governance%20Requirements%20V2%20(2).pdf
        {
          k: "daorequirements",
          v: "ipfs://bafybeic7cadmyc2r426iq7cnfoa3khdtnhk7crnyh3vhfciamwo35waizy",
        },
      ].map(({ k, v }) =>
        resolverInterface.encodeFunctionData("setText", [subnode, k, v])
      );
      // Return pending tx
      return factoryContract.createAlbum(
        albumId,
        [symbol, parseUnits(totalSupply, 18).toString()],
        [
          tokensPerWei,
          saleStart.toString(),
          saleEnd.toString(),
          parseUnits(amountSold, 18).toString(),
        ],
        Object.entries(nftsArg),
        resolverCalldata,
        { gasLimit: "8000000" }
      );
    },
    [name, symbol, nfts]
  );

  //Backend call to save metadata of album created
  const createAlbumDetails = useCallback(
    async (snapshotHash: string, tx: string): Promise<any> => {
      const api = new Api();

      return api.createFundDetails(
        user.uid,
        name,
        nfts,
        description,
        symbol,
        snapshotHash,
        tx,
        banner
      );
    },
    [user, name, nfts, description, symbol]
  );

  const createSnapshotHash = useCallback(async (): Promise<any> => {
    const api = new Api();
    return api.createSnapshotHash(name, symbol);
  }, [name, symbol]);

  const createAlbumCallback = useCallback(async (): Promise<any> => {
    try {
      const snapshotResp = await createSnapshotHash();
      const createAlbumResp = await createAlbumContract(snapshotResp.data.hash);
      dispatch(
        addTransaction({
          chainId: CHAIN_ID,
          from: createAlbumResp.from,
          hash: createAlbumResp.hash,
          info: {
            type: TransactionType.ALBUM_CREATION,
          },
        })
      );
      addPopup({
        txn: {
          hash: createAlbumResp.hash,
        },
      });
      const createAlbumDetailsResp = await createAlbumDetails(
        snapshotResp.data.hash,
        createAlbumResp.hash
      );
      return createAlbumResp;
    } catch (error) {
      console.log(error);
    }
  }, [createAlbumDetails, createSnapshotHash, createAlbumContract]);

  return { createAlbumCallback };
};
