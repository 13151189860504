import React, { FC, useState } from "react";
import Button from "../../../button/button";
import Input from "../input";
import { uniqueId } from "lodash";

interface ImageInputProps {
  label?: string;
  error?: string;
  onChange?: (e) => void;
  value?: string | undefined;
  width?: number;
  accept?: string;
}

//This is the button to upload images
const ImageInput: FC<ImageInputProps> = ({
  label,
  error,
  onChange = (e) => {},
  value,
  width,
  accept = "image/*",
}) => {
  //Generate a unique id so we map the label to the input components
  const [id] = useState(uniqueId("file-input"));

  return (
    <div className="flex flex-col space-y-8">
      <Input label={label} error={error} width={width}>
        <input
          id={id}
          type="file"
          accept={accept}
          className="hidden"
          // value={value}
          onChange={onChange}
        />
        <Button fill={false} width="full">
          <label
            className="w-full h-full absolute cursor-pointer"
            htmlFor={id}
          ></label>
          Upload
        </Button>
      </Input>
    </div>
  );
};

export default ImageInput;
