import { navigate } from "gatsby";
import React, { useState } from "react";
import ApproveNfts from "./approveNfts";
import CreateAlbumHeader from "../../createAlbumHeader";

const Approval = () => {
  const [isAllApproved, setIsAllApproved] = useState(false);
  const checkIsAllApproved = (isAllApproved: boolean) => {
    setIsAllApproved(isAllApproved);
  };

  return (
    <div className="flex flex-col space-y-32 h-full">
      <CreateAlbumHeader
        title="Approve contracts"
        continueDisabled={!isAllApproved}
        onContinue={() => navigate("/account/create?step=confirm")}
      />
      <div className="overflow-y-auto flew flow-col space-y-32">
        <p className="body1 text-dark-white-64 text-center">
          Approval is required once per NFT contract
        </p>
        <ApproveNfts onUpdate={checkIsAllApproved} />
      </div>
    </div>
  );
};

export default Approval;
