import { useEffect, useState } from "react";

import SushiSwapService from "../../services/sushiswap";

export const useEthPrice = (): any => {
  const [ethPrice, setEthPrice] = useState<any | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const sushi = new SushiSwapService();
    sushi
      .getEthPrice()
      .then((resp) => {
        setEthPrice(resp);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, []);

  return [ethPrice, loading];
};
