import React, { FC, useEffect, useState } from "react";
import { CONTRACT_ADDRS } from "../../../../../constants/contractAddrs";
import { useSelectedNftContracts } from "../../../../../state/createAlbum/hooks";
import NftApproveStatus from "./nftApproveStatus";

interface ApproveNftsProps {
  onUpdate?: (isAllApproved: boolean) => void;
}

const ApproveNfts: FC<ApproveNftsProps> = ({
  onUpdate = (isAllApproved) => {},
}) => {
  const [contractList, contractMap] = useSelectedNftContracts();

  const [contractApproveMap, setContractApproveMap] = useState<
    Map<string, boolean>
  >(new Map<string, boolean>());

  useEffect(() => {
    if (contractList) {
      let tmpMap = contractApproveMap;
      contractList.map((contract) => {
        tmpMap.set(contract.contract, false);
      });
    }
  }, [contractList]);

  const updateApproval = (contractAddr) => {
    let tmp = contractApproveMap;
    tmp.set(contractAddr, true);

    setContractApproveMap(tmp);

    let checkAllArray = [];
    contractApproveMap.forEach((value) => {
      checkAllArray.push(value);
    });

    onUpdate(checkAllArray.every(Boolean));
  };

  const largeColumns = (contractListLength) => {
    if (contractListLength === 1) {
      return 1;
    } else if (contractListLength === 2) {
      return 2;
    } else if (contractListLength >= 3) {
      return 3;
    }
  };

  return (
    <div className="flex flex-col md:justify-center items-center w-full h-full">
      <div
        className={`grid grid-cols grid-cols-1 md:grid-cols-2 gap-24 mx-auto flex-grow pb-16 max-h-full overflow-y-auto lg:grid-cols-${largeColumns(
          contractList.length
        )}`}
      >
        {contractList.map((contract) => (
          <NftApproveStatus
            key={contract.contract}
            contract={contract}
            selectedNfts={contractMap.get(contract.contract)}
            isApprovedCallback={updateApproval}
            operatorAddr={CONTRACT_ADDRS.ALBUM_FACTORY}
          />
        ))}
      </div>
    </div>
  );
};

export default ApproveNfts;
