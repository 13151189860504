import React, { useEffect, useState } from "react";
import { navigate, useLocation } from "@reach/router";
import queryString from "query-string";
import { useWhitelist } from "../../../hooks/whitelist/useWhitelist";
import Loading from "../../loading/loading";
import Button from "../../common/button/button";
import CreateDetails from "./steps/createDetails";
import { useDispatch, useSelector } from "react-redux";
import { resetAlbumSetup } from "../../../state/createAlbum/actions";
import SelectNft from "./steps/selectNft";
import Approval from "./steps/approve/approval";
import Confirm from "./steps/confirm";
import { AppState } from "../../../state";
import { useDeviceDetect } from "../../../hooks/useDeviceDetect";

const CreateAlbum = () => {
  const location = useLocation();
  const [step, setStep] = useState("");
  const { isMobile } = useDeviceDetect();

  const [isWhitelisted, whitelistLoading] = useWhitelist();

  const dispatch = useDispatch();

  const name = useSelector<
    AppState,
    AppState["createAlbum"]["details"]["name"]
  >((state) => state.createAlbum.details.name);

  useEffect(() => {
    //this makes sure we always enter on step 1 on initial render
    const query = location?.search;
    if (query && !name) {
      navigate("/account/create");
    }
    //Clear the redux state if the user navigates away from the create album page
    return () => {
      dispatch(resetAlbumSetup());
    };
  }, []);

  useEffect(() => {
    const query = location?.search;
    if (query) {
      const { step } = queryString.parse(query);
      if (step) {
        setStep(step);
      } else {
        setStep("");
      }
    } else {
      setStep("");
    }
  }, [location]);

  //Redirect if mobile navigates here
  if (isMobile) {
    navigate("/account/albums");
  }

  //Loading profile and checking whitelist...
  if (whitelistLoading) {
    return (
      <div className="flex flex-col mx-auto">
        <div className="flex flex-col mx-auto">
          <h1 className="text-white">Getting ready...</h1>
          <div className="mt-16 mx-auto">
            <Loading />
          </div>
        </div>
      </div>
    );
  } else if (!isWhitelisted) {
    return (
      <div className="flex flex-col justify-center items-center mx-auto space-y-32">
        <h3 className="text-white">
          Album creation is not yet publicly available
        </h3>
        <a href="https://discord.gg/HVsbDm64" target="_blank">
          <Button>
            Join our discord to ask question or apply for whitelist!
          </Button>
        </a>
      </div>
    );
  } else {
    //Details
    //NFT select
    //Approve
    //Confirm
    //Complete
    //Default: Details
    switch (step) {
      case "":
        return (
          <div className="mx-32 flex flex-col flex-grow">
            <CreateDetails />;
          </div>
        );
      case "addNft":
        // Height calculated here to subtract for header so we can scroll in the child
        return (
          <div
            className="mx-32 flex flex-col flex-grow"
            style={{ height: "calc(100vh - 104px)" }}
          >
            <SelectNft />
          </div>
        );
      case "approval":
        return (
          <div className="mx-32 flex flex-col flex-grow">
            <Approval />
          </div>
        );
      case "confirm":
        return (
          <div className="flex flex-col flex-grow mx-32 mb-32">
            <Confirm />
          </div>
        );
      case "complete":
        return null;
      default:
        return (
          <div className="mx-32 flex flex-col flex-grow">
            <CreateDetails />;
          </div>
        );
    }
  }
};

export default CreateAlbum;
