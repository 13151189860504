import React, { FC } from "react";
import { NFTExport } from "../../../models/exports";
import { CreateAlbumDetails } from "../../../state/createAlbum/reducer";
import ProfileImg from "../../common/image/profileImg";
import PreviewCollage from "../previewCollage";
import { shortenString } from "../../../utils/utils";

interface CreatePreviewProps {
  creator: string;
  details: CreateAlbumDetails;
  selectedNfts: NFTExport[];
}

const CreatePreview: FC<CreatePreviewProps> = ({
  creator,
  details,
  selectedNfts,
}) => {
  return (
    <div
      className="flex flex-col space-y-16 bg-black rounded-sm p-8"
      style={{ width: "326px", height: "216px" }}
    >
      <div className="flex flex-row space-x-16 items-center p-8">
        <ProfileImg height={48} width={48} url={details.pic} />
        <div>
          <h2 className="text-white">{shortenString(details.name, 15)}</h2>
          <p className="body1 text-dark-white-64">
            {creator} •{" "}
            {`${selectedNfts.length} NFT${selectedNfts.length > 1 ? "s" : ""}`}
          </p>
        </div>
      </div>
      <div className="flex w-full overflow-hidden" style={{ height: "128px" }}>
        <PreviewCollage
          images={
            selectedNfts.length > 5
              ? selectedNfts.slice(0, 5).map((value) => value.thumbnailURL)
              : selectedNfts.map((value) => value.thumbnailURL)
          }
        />
      </div>
    </div>
  );
};

export default CreatePreview;
