import React, { useEffect } from "react";
import { useState } from "react";
import Api from "../../../../services/api";
import { parseUnixSeconds, toUnixSeconds } from "../../../../utils/time";
import Button from "../../../common/button/button";
import Card from "../../../common/card/card";
import DateTimeInput from "../../../common/form/input/datetimeInput";
import ImageUpload from "../../../common/form/input/image/imageUpload";
import NumberInput from "../../../common/form/input/numberInput";
import TextArea from "../../../common/form/input/textArea";
import TextInput from "../../../common/form/input/textInput";
import CreateAlbumHeader from "../createAlbumHeader";
import { useCreateAlbumDetails } from "../../../../state/createAlbum/hooks";
import { CreateAlbumDetails } from "../../../../state/createAlbum/reducer";
import { navigate } from "gatsby";
import { DateTime } from "luxon";
import { useEthPrice } from "../../../../hooks/album/useEthPrice";
import { wholeOrDecimal } from "../../../../utils/utils";
import { BigNumber, ethers } from "ethers";
import { TOKEN_SALE_DURATION } from "../../../../constants";
import { Duration } from "luxon";
import { useEthers } from "@usedapp/core";
import { getENSOwner } from "../../../../utils/web3/ens/util";

const CreateDetails = () => {
  const { library } = useEthers();

  const [ethPrice, ethPriceLoading] = useEthPrice();
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const checkName = () => {
    if (name) {
      getENSOwner(library, name)
        .then((response) => {
          if (response === ethers.constants.AddressZero) {
            const api = new Api();
            api
              .checkAlbumName(name)
              .then((response) => {
                if (!response.data.available) {
                  setNameError("Album name taken");
                } else {
                  setNameError("");
                }
              })
              .catch((error) => {
                console.log(error);
              });
          } else {
            setNameError("Album name taken");
          }
        })
        .catch((error) => {
          console.log(error);
          setNameError("Album name taken");
        });
    }
  };

  const handleNameChange = (e) => {
    e.preventDefault();

    const regex = new RegExp("^[a-zA-Z0-9 ]*[a-zA-Z0-9][a-zA-Z0-9 ]*$");
    const match = e.target.value.match(regex);
    if (e.target.value === "") {
      setNameError("Please enter an Album name");
      setName(e.target.value);
    } else if (match) {
      setNameError("");
      setName(e.target.value);
    }
  };

  const [description, setDescription] = useState("");

  const [pic, setPic] = useState<string>("");
  const handlePicChange = (imageUrl) => {
    if (imageUrl) {
      setPic(imageUrl);
    }
  };

  const [tokenName, setTokenName] = useState("");
  const [tokenNameError, setTokenNameError] = useState("");

  const checkTokenName = () => {
    const api = new Api();

    api
      .checkTokenName(tokenName)
      .then((response) => {
        if (!response.data.available) {
          setTokenNameError("Token name taken");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleTokenNameChange = (e) => {
    e.preventDefault();
    const regex = new RegExp("^[A-Z0-9]*$");
    const match = e.target.value.toUpperCase().match(regex);

    if (e.target.value === "") {
      setTokenNameError("Please enter a Token name");
      setTokenName(e.target.value);
    } else if (match) {
      setTokenNameError("");
      setTokenName(e.target.value.toUpperCase());
    }
  };

  const [totalSupply, setTotalSupply] = useState("");
  const [totalSupplyError, setTotalSupplyError] = useState("");
  useEffect(() => {
    if (totalSupply && parseFloat(totalSupply) < 100) {
      setTotalSupplyError("Needs to be greater than 100");
    } else {
      setTotalSupplyError("");
    }
  }, [totalSupply]);
  const [amountSold, setAmountSold] = useState("");
  const [amountSoldError, setAmountSoldError] = useState("");
  useEffect(() => {
    if (parseFloat(amountSold) > 1e18) {
      setAmountSoldError("Maximum value is 1e18");
    } else if (
      totalSupply &&
      amountSold &&
      Number.parseInt(totalSupply) < Number.parseInt(amountSold)
    ) {
      setAmountSoldError("Sell amount larger than total supply");
    } else if (
      totalSupply &&
      amountSold &&
      Number.parseFloat(amountSold) >
        Math.floor(Number.parseFloat(totalSupply) * 0.99)
    ) {
      setAmountSoldError(
        `Must reserve 1% of supply for sznsDAO (max: ${Math.floor(
          Number.parseFloat(totalSupply) * 0.99
        )})`
      );
    } else {
      setAmountSoldError("");
    }
  }, [totalSupply, amountSold]);

  const [tokensPerMilliEthError, setTokensPerMilliEthError] = useState("");

  const [tokensPerEth, setTokensPerEth] = useState("");
  useEffect(() => {
    if (amountSold && tokensPerEth) {
      if (parseFloat(tokensPerEth) < 1) {
        setTokensPerMilliEthError("Minimum value is 1");
      } else if (parseFloat(tokensPerEth) > 1e18) {
        setTokensPerMilliEthError("Maximum value is 1e18");
      } else {
        setTokensPerMilliEthError("");
      }
    }
  }, [tokensPerEth, amountSold]);
  const handleTokensPerEth = ({ value }) => {
    setTokensPerEth(value);
  };

  const [saleStart, setSaleStart] = useState("");
  const [saleStartError, setSaleStartError] = useState("");

  const [saleEnd, setSaleEnd] = useState("");
  const [saleEndError, setSaleEndError] = useState("");
  useEffect(() => {
    if (saleStart && saleEnd) {
      let start = new Date(saleStart);
      let end = new Date(saleEnd);

      let luxStart = DateTime.fromJSDate(start);
      let luxEnd = DateTime.fromJSDate(end);
      if (saleEnd !== "" && isNaN(end.getTime())) {
        setSaleEndError("Please enter a valid end date");
      } else if (toUnixSeconds(luxStart) >= toUnixSeconds(luxEnd)) {
        setSaleEndError("End date before start date");
      } else {
        setSaleEndError("");
      }
    } else {
      setSaleEndError("");
    }
  }, [saleStart, saleEnd]);

  useEffect(() => {
    let start = new Date(saleStart);

    if (saleStart !== "" && isNaN(start.getTime())) {
      setSaleStartError("Please enter a valid start date");
    } else {
      setSaleStartError("");
    }
  }, [saleStart]);

  const isFormValid = (): boolean => {
    //If token sale set make sure we have valid params
    const doTokenSale = Boolean(
      amountSold.length > 0 &&
        parseInt(amountSold) > 0 &&
        tokensPerEth.length > 0 &&
        saleStart.length > 0 &&
        saleEnd.length > 0 &&
        !tokensPerMilliEthError &&
        !saleStartError &&
        !saleEndError
    );

    // Either we have a token sale or we don't but need explicit amountSold === 0
    const validTokenSale =
      doTokenSale || (amountSold.length > 0 && parseInt(amountSold) === 0);

    return Boolean(
      name.length > 0 &&
        description.length > 0 &&
        pic.length > 0 &&
        tokenName.length > 0 &&
        totalSupply.length > 0 &&
        validTokenSale &&
        !nameError &&
        !tokenNameError &&
        !amountSoldError &&
        !totalSupplyError &&
        !totalSupplyError
    );
  };

  const { details, set } = useCreateAlbumDetails();

  //Check if there was prior create info
  useEffect(() => {
    setName(details.name);
    setDescription(details.description);
    if (details.pic) {
      setPic(details.pic);
    }
    setTokenName(details.tokenName);
    setTotalSupply(details.totalSupply);
    setAmountSold(details.amountSold);

    //convert tokens per millieth (0.001) to tokens per eth(1)
    if (details.tokensPerWei) {
      const tmpTokensPerEth = details.tokensPerWei;
      setTokensPerEth(tmpTokensPerEth.toString());
    }
    if (details.saleStart > 0) {
      setSaleStart(parseUnixSeconds(details.saleStart));
    }
    if (details.saleEnd > 0) {
      setSaleEnd(parseUnixSeconds(details.saleEnd));
    }
  }, [details]);

  //Save to redux state create album details info
  const onContinue = (e) => {
    const details: CreateAlbumDetails = {
      name: name,
      description: description,
      pic: pic,
      tokenName: tokenName,
      totalSupply: totalSupply,
      amountSold: amountSold,
      tokensPerWei: tokensPerEth ? BigNumber.from(tokensPerEth)._hex : "0",
      saleStart: saleStart ? toUnixSeconds(saleStart) : 0,
      saleEnd: saleEnd ? toUnixSeconds(saleEnd) : 0,
    };

    set(details);

    navigate("/account/create?step=addNft");
  };

  return (
    <div className="flex flex-col space-y-32">
      <CreateAlbumHeader
        title="Create new album"
        continueDisabled={!isFormValid()}
        onContinue={onContinue}
      />
      <div className="flex flex-row space-x-24 mx-auto">
        <div className="flex flex-col space-y-24" style={{ width: "343px" }}>
          <Card title="Details">
            <TextInput
              label="album name"
              placeholder="Name your album"
              value={name}
              onChange={handleNameChange}
              onBlur={checkName}
              error={nameError}
            />
            <TextArea
              minRows={5}
              value={description}
              maxLength={1000}
              placeholder="Add a short description"
              onChange={(e) => setDescription(e.target.value)}
            />
          </Card>
          <ImageUpload
            previewSize="profile"
            title="Album profile pic"
            onChange={handlePicChange}
            subtitle={
              "Recommended size 500x500px\nJPG, PNG, or GIF\n10MB max size"
            }
          />
        </div>
        <div style={{ width: "343px" }}>
          <Card title="Token">
            <TextInput
              label="token name"
              placeholder="$ALBUM"
              value={tokenName}
              onChange={handleTokenNameChange}
              onBlur={checkTokenName}
              error={tokenNameError}
              maxLength={8}
            />
            <NumberInput
              label="total supply"
              placeholder="100,000 $ALBUM"
              value={totalSupply}
              defaultValue={100000}
              suffix={` $${tokenName}`}
              onChange={({ value }) => setTotalSupply(value)}
              decimalScale={0}
              error={totalSupplyError}
            />
            <NumberInput
              label="amount of tokens to sell"
              placeholder="15,000 $ALBUM"
              value={amountSold}
              defaultValue={15000}
              suffix={` $${tokenName}`}
              onChange={({ value }) => setAmountSold(value)}
              error={amountSoldError}
              decimalScale={0}
            />
            {amountSold && parseInt(amountSold) > 0 && (
              <>
                <NumberInput
                  label="tokens per eth"
                  placeholder="1,000 $ALBUM"
                  value={tokensPerEth}
                  suffix={` $${tokenName}`}
                  onChange={handleTokensPerEth}
                  decimalScale={0}
                  error={tokensPerMilliEthError}
                />
                <div className="flex flex-col space-y-8 body2 text-white">
                  <p className="caption">total raise at token sellout</p>

                  <div className="border-b-2 border-white text-right pb-8">
                    <div className="flex flex-col space-x-4 justify-end">
                      <div className="flex flex-row justify-end">
                        <p
                          className={`${
                            amountSold ? "text-white" : "text-dark-white-64"
                          } break-all`}
                        >
                          {amountSold
                            ? parseFloat(amountSold).toLocaleString()
                            : "Total"}
                        </p>
                        <p
                          className={`${
                            tokenName ? "text-white" : "text-dark-white-64"
                          } ml-4`}
                        >
                          ${tokenName ? tokenName : "ALBUM"}
                        </p>
                      </div>
                      <div
                        className={`${
                          tokensPerEth && tokenName
                            ? "text-white"
                            : "text-dark-white-64"
                        } flex flex-row justify-end`}
                      >
                        <p>&divide;</p>
                        <p className="ml-4 break-all">
                          {tokensPerEth
                            ? `${parseFloat(tokensPerEth).toLocaleString()}`
                            : ""}
                        </p>
                        <p className="ml-4">
                          {tokenName ? `$${tokenName}/ETH` : "$ALBUM/ETH"}
                        </p>
                      </div>
                    </div>
                  </div>
                  <p
                    className={`${
                      tokensPerEth && amountSold
                        ? "text-white"
                        : "text-dark-white-64"
                    } text-right`}
                  >
                    {tokensPerEth && amountSold
                      ? `${wholeOrDecimal(
                          Number.parseFloat(amountSold) /
                            Number.parseFloat(tokensPerEth)
                        ).toLocaleString()} ETH`
                      : "Total ETH raise"}
                  </p>
                  <p className="caption">implied album market cap</p>
                  <p
                    className={`${
                      tokensPerEth && totalSupply
                        ? "text-white"
                        : "text-dark-white-64"
                    } text-right`}
                  >
                    {tokensPerEth && totalSupply && ethPrice
                      ? `~${wholeOrDecimal(
                          Number.parseFloat(totalSupply) /
                            Number.parseFloat(tokensPerEth)
                        ).toLocaleString()} ETH (~$${wholeOrDecimal(
                          (Number.parseFloat(totalSupply) /
                            Number.parseFloat(tokensPerEth)) *
                            ethPrice
                        ).toLocaleString()})`
                      : "Total ETH (Total USD)"}
                  </p>
                </div>
              </>
            )}
          </Card>
        </div>
        <div className="flex flex-col space-y-24" style={{ width: "343px" }}>
          {amountSold && parseInt(amountSold) > 0 && (
            <Card title="Sale date">
              <DateTimeInput
                label="token sale start date"
                value={saleStart}
                onChange={(e) => setSaleStart(e.target.value)}
                placeholder={"MM/DD/YYYY 00:00 AM"}
                error={saleStartError}
              />
              <DateTimeInput
                label="token sale end date"
                value={saleEnd}
                onChange={(e) => setSaleEnd(e.target.value)}
                placeholder={"MM/DD/YYYY 00:00 AM"}
                error={saleEndError}
              />
            </Card>
          )}
        </div>
      </div>
      <div className="self-end w-1/4">
        <Button disabled={!isFormValid()} onClick={onContinue} width="full">
          Continue
        </Button>
      </div>
    </div>
  );
};

export default CreateDetails;
