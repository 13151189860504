import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "..";
import { NFTExport, NFTType } from "../../models/exports";
import {
  backwardStep,
  forwardStep,
  setStep,
  setDetails,
  resetAlbumSetup,
} from "./actions";
import { CreateAlbumDetails, CreateAlbumStep } from "./reducer";

export const useSelectedNfts = (): NFTExport[] => {
  const selectedNFTs = useSelector<
    AppState,
    AppState["createAlbum"]["selectedNfts"]
  >((state) => state.createAlbum.selectedNfts);

  return selectedNFTs;
};

//Check is contained inside our list
const isContractAddrExist = (
  contractList: NFTType[],
  address: string
): boolean => {
  let alreadyExists = false;
  contractList.map((contract) => {
    if (contract.contract === address) {
      alreadyExists = true;
    }
  });

  return alreadyExists;
};

//Returns a list of NFT contracts, via the NFTType object, for our current selected NFTs
export const useSelectedNftContracts = (): [
  NFTType[],
  Map<string, NFTExport[]>
] => {
  const selectedNFTs = useSelector<
    AppState,
    AppState["createAlbum"]["selectedNfts"]
  >((state) => state.createAlbum.selectedNfts);

  //Array of NFTType
  const [contractList, setContractList] = useState<NFTType[]>([]);

  //Map of contract address to the current selected NFTs
  const [contractMap, setContractMap] = useState<Map<string, NFTExport[]>>();

  useEffect(() => {
    if (selectedNFTs) {
      let tmpList: NFTType[] = [];
      let tmpMap: Map<string, NFTExport[]> = new Map<string, NFTExport[]>();
      selectedNFTs.map((nft) => {
        const contractAddr = nft.type.contract;

        //Get the contract info
        if (!isContractAddrExist(tmpList, contractAddr)) {
          tmpList.push(nft.type);
        }

        //Then map contract to the nft
        if (!tmpMap.get(contractAddr)) {
          tmpMap.set(contractAddr, []);
        }

        tmpMap.set(contractAddr, [...tmpMap.get(contractAddr), ...[nft]]);
      });

      setContractList(tmpList);
      setContractMap(tmpMap);
    }
  }, [selectedNFTs]);

  return [contractList, contractMap];
};

export const useCreateAlbumStep = (): {
  step: CreateAlbumStep;
  back: Function;
  next: Function;
  set: Function;
  reset: Function;
} => {
  const step = useSelector<AppState, AppState["createAlbum"]["createStep"]>(
    (state) => state.createAlbum.createStep
  );
  const dispatch = useDispatch();

  const back = useCallback(() => {
    dispatch(backwardStep());
  }, []);

  const next = useCallback(() => {
    dispatch(forwardStep());
  }, []);

  const set = useCallback((step: CreateAlbumStep) => {
    dispatch(setStep(step));
  }, []);

  const reset = useCallback(() => {
    dispatch(resetAlbumSetup());
  }, []);

  return {
    step: step,
    back: back,
    next: next,
    set: set,
    reset: reset,
  };
};

export const useCreateAlbumDetails = (): {
  details: CreateAlbumDetails;
  set: Function;
} => {
  const details = useSelector<AppState, AppState["createAlbum"]["details"]>(
    (state) => state.createAlbum.details
  );

  const dispatch = useDispatch();
  const set = useCallback((details: CreateAlbumDetails) => {
    dispatch(setDetails(details));
  }, []);

  return {
    details: details,
    set: set,
  };
};
