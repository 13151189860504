import React, { FC, useEffect, useState } from "react";
import Card from "../../../card/card";
import BannerImg from "../../../image/bannerImg";
import ProfileImg from "../../../image/profileImg";
import ImageInput from "./imageInput";

interface ImageUploadProps {
  title?: string;
  subtitle?: string;
  error?: string;
  onChange?: (imageUrl: string | undefined) => void;
  url?: string;
  previewSize?: "profile" | "banner";
}

//This is the component card to upload and preview images
const ImageUpload: FC<ImageUploadProps> = ({
  title,
  subtitle,
  error,
  onChange = () => {},
  url,
  previewSize = "banner",
}) => {
  const [imageUrl, setImageUrl] = useState<string | undefined>(url);

  const handleChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageUrl(URL.createObjectURL(file));
    }
  };

  useEffect(() => {
    onChange(imageUrl);
  }, [imageUrl]);

  return (
    <Card title={title}>
      <div className="flex flex-col space-y-24">
        <p
          className="body1 whitespace-pre-wrap"
          style={{ wordBreak: "normal", overflowWrap: "anywhere" }}
        >
          {subtitle}
        </p>
        <div className={`${imageUrl || url ? "" : "hidden"} mx-auto`}>
          {previewSize === "banner" ? (
            <div style={{ aspectRatio: "3/1" }}>
              <BannerImg url={imageUrl || url} />
            </div>
          ) : (
            <ProfileImg url={imageUrl || url} />
          )}
        </div>
        <ImageInput error={error} onChange={handleChange} value={imageUrl} />
      </div>
    </Card>
  );
};

export default ImageUpload;
