import React from "react";
import NFTSelector from "../../../common/nft/selector/nftSelector";
import firebase from "gatsby-plugin-firebase";
import {
  useCreateAlbumDetails,
  useSelectedNfts,
} from "../../../../state/createAlbum/hooks";
import CreateAlbumHeader from "../createAlbumHeader";
import Search from "../../../common/search/search";
import { useState } from "react";
import { navigate } from "gatsby";
import { useDispatch } from "react-redux";
import {
  addNft,
  clearNft,
  removeNft,
} from "../../../../state/createAlbum/actions";

const SelectNft = () => {
  const userAddress = firebase?.auth()?.currentUser?.uid;
  const { details, set } = useCreateAlbumDetails();
  const [filter, setFilter] = useState("");
  const selectedNfts = useSelectedNfts();
  const dispatch = useDispatch();

  const selectedNFTs = useSelectedNfts();

  return (
    <div className="flex flex-col h-full">
      <CreateAlbumHeader
        title="Select NFTs"
        continueDisabled={selectedNfts.length === 0}
        onContinue={() => navigate("/account/create?step=approval")}
      >
        <div className="mx-auto" style={{ width: "78%" }}>
          <Search
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            onClear={() => setFilter("")}
          />
        </div>
      </CreateAlbumHeader>
      <div className="flex flex-col flex-grow min-h-0">
        <NFTSelector
          walletAddress={userAddress}
          tokenName={details.tokenName}
          filter={filter}
          addNft={(nft) => dispatch(addNft(nft))}
          removeNft={(nft) => dispatch(removeNft(nft))}
          clearNft={() => dispatch(clearNft())}
          selectedNFTs={selectedNFTs}
        />
      </div>
    </div>
  );
};

export default SelectNft;
