import React, { useState } from "react";
import ExpandRowWithBtn from "../common/expand/expandRowWithBtn";
import firebase from "gatsby-plugin-firebase";
import { useGetAlbumsCreated } from "../../hooks/albums/useGetAlbumsCreated";
import { useGetAlbumsOwned } from "../../hooks/albums/useGetAlbumsOwned";
import { navigate } from "gatsby";
import Preview from "../album/preview";
import Loading from "../loading/loading";
import Button from "../common/button/button";
import Modal from "../common/modal/modal";
import ModalTitle from "../common/modal/modalTitle";
import ModalSubtitle from "../common/modal/modalSubtitle";
import CloseIcon from "@mui/icons-material/Close";

const AlbumOverview = () => {
  const userAddress = firebase?.auth()?.currentUser?.uid;

  const [curated, curatedLoading] = useGetAlbumsCreated(userAddress);
  const [owned, ownedLoading] = useGetAlbumsOwned(userAddress);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      className="flex flex-col space-y-16 md:space-y-32 w-full mx-auto px-16 pt-16 pb-16 md:px-0 md:pt-0 md:pb-56"
      style={{ maxWidth: "1026px" }}
    >
      <div className="flex flex-row justify-between items-center items-end text-white">
        <h1 className="md:display1">Albums</h1>
        <a
          className="btn-second text-dark-white-64 hover:text-white"
          href="https://app.gitbook.com/@szns/s/szns/"
          target="_blank"
        >
          Learn about Albums
        </a>
      </div>
      {curatedLoading || ownedLoading ? (
        <div className="flex flex-col h-full place-content-center">
          <div className="mt-16 mx-auto">
            <Loading size={32} />
          </div>
        </div>
      ) : (
        <>
          <div className="space-y-24 md:space-y-32">
            <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
              <>
                <ModalTitle>
                  <h2 className="text-white">Switch to Desktop</h2>
                  <button
                    className="focus:outline-none"
                    onClick={() => setIsOpen(false)}
                  >
                    <CloseIcon className="fill-current text-white" />
                  </button>
                </ModalTitle>
                <div className="flex flex-col space-y-24 mt-16">
                  <ModalSubtitle>
                    <p className="body1 text-dark-white-64 text-left">
                      Album creation is limited to desktop.
                      <br /> Please switch over to desktop if you would like to
                      create an album.
                    </p>
                  </ModalSubtitle>
                </div>
              </>
            </Modal>
            <div className="border-t border-b border-dark-divider py-24 md:py-32 fade-in">
              {/* create */}
              <ExpandRowWithBtn
                label={
                  curated?.length > 0
                    ? `${curated.length} created`
                    : "No albums created yet"
                }
                defaultOpen={true}
                buttonText="Create new album"
                onClick={() => navigate("/account/create")}
              >
                <div className="mt-4 grid grid-cols-1 md:grid-cols-2 xlg:grid-cols-3 gap-16 lg:gap-28 xlg:gap-24">
                  {curated &&
                    curated.map((album) => (
                      <div
                        className="flex"
                        key={album.name}
                        style={{ height: "216px" }}
                      >
                        <Preview
                          owner={album.creator}
                          address={album.albumAddress}
                          id={album.id}
                          name={album.name}
                          nfts={album.nfts}
                          slim={true}
                          state={album.state}
                          disabled={album.state === "pending"}
                        />
                      </div>
                    ))}
                </div>
                <div
                  className={`${
                    curated?.length > 0 ? "hidden" : ""
                  } flex md:hidden flex-row justify-center py-16`}
                >
                  <Button
                    size="md"
                    fill={false}
                    onClick={() => setIsOpen(true)}
                  >
                    Create new album
                  </Button>
                </div>
              </ExpandRowWithBtn>
            </div>
            <div className="fade-in">
              {/* owned */}
              <ExpandRowWithBtn
                label={
                  owned?.length > 0
                    ? `${owned.length} owned`
                    : "No albums owned yet"
                }
                defaultOpen={true}
                buttonText="Explore albums"
                onClick={() => navigate("/explore")}
              >
                <div className="mt-4 grid grid-cols-1 md:grid-cols-2 xlg:grid-cols-3 gap-16 lg:gap-28 xlg:gap-24">
                  {owned &&
                    owned.map((album) => (
                      <div
                        className="flex"
                        key={album.name}
                        style={{ height: "216px" }}
                      >
                        <Preview
                          owner={album.creator}
                          address={album.albumAddress}
                          id={album.id}
                          name={album.name}
                          nfts={album.nfts}
                          slim={true}
                        />
                      </div>
                    ))}
                </div>
                <div className="flex md:hidden flex-row justify-center py-16">
                  <Button
                    size="md"
                    fill={false}
                    width={`${owned.length > 0 ? "full" : null}`}
                    onClick={() => navigate("/explore")}
                  >
                    Explore albums
                  </Button>
                </div>
              </ExpandRowWithBtn>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default AlbumOverview;
