import React, { FC } from "react";
import Button from "../../common/button/button";

interface CreateAlbumHeaderProps {
  title?: string;
  showContinue?: boolean;
  continueDisabled?: boolean;
  onContinue?: (e) => void;
  celebrate?: boolean;
}

const celebrateStyle = {
  background:
    "linear-gradient(270deg, #FF4041 0%, #FF6190 29.69%, #FF8B4B 57.81%, #85D6F7 100%)",
  backgroundSize: "400% 400%",
  animation: "AnimationName 2s ease infinite",
  MozAnimation: "AnimationName 2s ease infinite",
  WebkitAnimation: "AnimationName 2s ease infinite",
  backgroundClip: "text",
  color: "transparent",
  "-webkit-background-clip": "text",
};

const CreateAlbumHeader: FC<CreateAlbumHeaderProps> = ({
  title,
  showContinue = true,
  continueDisabled = true,
  onContinue = (e) => {},
  celebrate = false,
  children,
}) => {
  return (
    <div className="w-full ">
      <div className="flex flex-row justify-between items-center text-white border-b border-dark-divider pb-32">
        <div className="display1" style={celebrate ? celebrateStyle : {}}>
          {title}
        </div>
        <div className="flex-grow">{children}</div>
        {showContinue && (
          <div className="flex flex-row space-x-32 items-end">
            <a
              className="btn-second text-dark-white-64 hover:text-white"
              href="https://app.gitbook.com/@szns/s/szns/"
              target="_blank"
            >
              Learn about Albums
            </a>
            <Button disabled={continueDisabled} onClick={onContinue}>
              Continue
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default CreateAlbumHeader;
